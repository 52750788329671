'use strict';

/**
 * Helper function for binding selectize to inputs
 */

// Require vendor dependencies
window.$ = window.jQuery = require('jquery');
require('selectize');

// Export taggify() method
module.exports = function taggify(context){

    // Bind selectize to inputs
    $('input.esensi-tags', context).selectize({
        persist: false,
        create: true,
        createOnBlur: true,
        plugins: ['remove_button'],
        sortField: [
            { field: '$order' }, // $order is the default field text
            { field: '$score' }, // $score is the ranking when searching for terms. Placing it here means that options are always alphabetical
        ],
    });

    // Bind selectize to selects
    $('select.esensi-tags[multiple]', context).selectize({
        persist: false,
        plugins: ['remove_button'],
        sortField: [
            { field: '$order' },
            { field: '$score' },
        ],
    });
    $('select.esensi-tags:not([multiple])', context).selectize({
        persist: true,
        create: false,
        plugins: ['remove_button'],
        mode: 'multi',
        sortField: [
            { field: '$order' },
            { field: '$score' },
        ],
    });
};
