"use strict";

/**
 * Company registration scripts
 */

// Require vendor dependencies
window.$ = window.jQuery = require('jquery');

// Do something after DOM is ready
$(function () {

    var form = $('.users-signup');

    // Toggle disable state of referral inputs on company create/edit form
    form.on('change', 'select[name=referral_type]', function(e)
    {
        var select = $(e.currentTarget);
        var fieldset = select.parents('fieldset').next('fieldset');
        fieldset.prop('disabled', select.val() !== 'Other' || select.val() === '');
    });
});
