'use strict';

/**
 * Default Esensi modal
 */

// Require vendor dependencies
window.$ = window.jQuery = require('jquery');
var bootstrap = require('bootstrap');

// Require custom dependencies
var Esensi = require('../common/_esensi');

// Add modal to the Esensi namespace
Esensi.modal = {};
Esensi.modal.context = $('#esensiModal');

// Add an init method to the modal method
Esensi.modal.init = function () {

};

$(function() {

    // Fix for modal reloads
    // http://stackoverflow.com/questions/12286332/
    $('#esensiModal').on('hidden.bs.modal', function () {
        $(this).removeData('bs.modal').find('.modal-content').empty();
    });
});
