'use strict';

/**
 * Avoid `console` errors in browsers that lack a console.
 */
var method;
var noop = function () {};
var methods = [
    'assert', 'clear', 'count', 'debug', 'dir', 'dirxml', 'error',
    'exception', 'group', 'groupCollapsed', 'groupEnd', 'info', 'log',
    'markTimeline', 'profile', 'profileEnd', 'table', 'time', 'timeEnd',
    'timeStamp', 'trace', 'warn'
];
var l = methods.length;
var c = (window.console = window.console || {});
while (l--) {
    method = methods[l];

    // Only stub undefined methods.
    if (!c[method]) {
        c[method] = noop;
    }
}

/**
 * Adds better support for IE10 and Windows 8 Phone
 *
 * @see http://getbootstrap.com/getting-started/#support-ie10-width
 */
if (navigator.userAgent.match(/IEMobile\/10\.0/)) {
    var msViewportStyle = document.createElement('style');
    msViewportStyle.appendChild(
        document.createTextNode(
            '@-ms-viewport{width:auto!important}'
        )
    );
    document.querySelector('head').appendChild(msViewportStyle);
}

/**
 * Add a 'loaded' event for modal callbacks
 *
 * @see https://github.com/twbs/bootstrap/pull/6846#issuecomment-19829874
 */
var $ = require('jquery');
$.fn.jqueryLoad = $.fn.load;
$.fn.load = function(url, params, callback) {
    var $this = $(this);
    var cb = $.isFunction(params) ? params: callback || $.noop;
    var wrapped = function(responseText, textStatus, XMLHttpRequest) {
        cb(responseText, textStatus, XMLHttpRequest);
        $this.trigger('loaded');
    };

    if ($.isFunction(params)) {
        params = wrapped;
    } else {
        callback = wrapped;
    }

    $this.jqueryLoad(url, params, callback);

    return this;
};

/**
 * Adds serializeObject support.
 *
 * @see http://stackoverflow.com/a/8407771
 */
$.fn.serializeObject = function(){

    var self = this,
        json = {},
        push_counters = {},
        patterns = {
            "validate": /^[a-zA-Z][a-zA-Z0-9_]*(?:\[(?:\d*|[a-zA-Z0-9_]+)\])*$/,
            "key":      /[a-zA-Z0-9_]+|(?=\[\])/g,
            "push":     /^$/,
            "fixed":    /^\d+$/,
            "named":    /^[a-zA-Z0-9_]+$/
        };


    this.build = function(base, key, value){
        base[key] = value;
        return base;
    };

    this.push_counter = function(key){
        if(push_counters[key] === undefined){
            push_counters[key] = 0;
        }
        return push_counters[key]++;
    };

    $.each($(this).serializeArray(), function(){

        // skip invalid keys
        if(!patterns.validate.test(this.name)){
            return;
        }

        var k,
            keys = this.name.match(patterns.key),
            merge = this.value,
            reverse_key = this.name;

        while((k = keys.pop()) !== undefined){

            // adjust reverse_key
            reverse_key = reverse_key.replace(new RegExp("\\[" + k + "\\]$"), '');

            // push
            if(k.match(patterns.push)){
                merge = self.build([], self.push_counter(reverse_key), merge);
            }

            // fixed
            else if(k.match(patterns.fixed)){
                merge = self.build([], k, merge);
            }

            // named
            else if(k.match(patterns.named)){
                merge = self.build({}, k, merge);
            }
        }

        json = $.extend(true, json, merge);
    });

    return json;
};
