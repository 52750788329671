"use strict";

/**
 * USDOT/MC Number Lookup Script
 */

// Require vendor dependencies
window.$ = window.jQuery = require('jquery');

// Do something after DOM is ready
$(function () {

    // Lookup the company's information using the government website
    $('[data-toggle="lookup"]').on('click', function(e)
    {
        e.preventDefault();
        var anchor = $(e.currentTarget);
        var form = anchor.parents('form');
        var group = anchor.parents('.form-group');
        var type = anchor.data('type');
        var number = $.trim(anchor.prev('input').val());
        var fieldset = form.find('.entity-fieldset');
        if( ! type || ! number ) return;

        // Disable inputs while the AJAX request is loading
        fieldset.attr('disabled', true);
        form.find('.has-error').removeClass('has-error');

        // Query the API to perform the lookup
        $.get('/api/companies/' + number + '/' + type + '-lookup', [], function(company)
        {
            // Company fields
            form.find('[name=dba_name]').val(company.dba_name);
            form.find('[name=legal_name]').val(company.legal_name);
            form.find('[name=mc_number]').val(company.mc_number);
            form.find('[name=phone_1]').val(company.phone);
            form.find('[name=type]').val('corporation');
            form.find('[name=usdot_number]').val(company.usdot_number);

            // Address fields
            var address = company.addresses[0];
            if(address !== 'undefined') {
                if (form.find('[name*="region"]').get(0).selectize !== undefined) {
                    form.find('[name*="region"]').get(0).selectize.clear();
                    form.find('[name*="region"]').get(0).selectize.addItem(address.region);
                } else {
                    form.find('[name*="region"]').first().val(address.region);
                }

                if (form.find('[name*="country"]').get(0).selectize !== undefined) {
                    form.find('[name*="country"]').get(0).selectize.clear();
                    form.find('[name*="country"]').get(0).selectize.addItem(address.country);
                } else {
                    form.find('[name*="country"]').first().val(address.country);
                }

                form.find('[name*="building"]').first().val(address.building);
                form.find('[name*="city"]').first().val(address.city);
                form.find('[name*="postalcode"]').first().val(address.postalcode);
                form.find('[name*="street"]').first().val(address.street);
            }
        })

        // Failure callback
        .fail(function(jqXhr, textStatus, errorThrown)
        {
            var error = jqXhr.responseJSON;
            console.log(errorThrown);

            // Show error state
            group.addClass('has-feedback has-error');
        })

        // Reactivate the form fields after AJAX request
        .always(function()
        {
            fieldset.attr('disabled', false);
        });
    });
});
