/*jshint node: true, jquery: true */

"use strict";

/**
 * Public application scripts
 */

// Require vendor dependencies

// Require jQuery. (CommonJS compatible if installed with NPM.)
// You must use this syntax to prevent issues, especially w/ Bootstrap.
window.$ = window.jQuery = require('jquery');

// Require AJAX transport for jQuery. (CommonJS compatible.)
require('jQuery-ajaxTransport-XDomainRequest');

// Require Bootstrap, Jasny-Bootstrap. (Uses browserify-shim.)
var bootstrap = require('bootstrap');
require('jasny-bootstrap');

// Bootstrap the application
require('./common/_start');
require('./common/_lookup');
require('./public/_modal');
require('./public/_register');

// Taggify elements on the page
var taggify = require('./common/_taggify');
taggify('body');
